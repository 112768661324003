import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import PreviewGrid from '../components/preview-grid'
import Seo from '../components/seo'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'
import { filterOutDocsWithoutSlugs, mapEdgesToNodes } from '../lib/helpers'

const ProjectsPage = ({ data, errors }) => {
  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout>
      <Seo title='Projects' />
      <h1 hidden>Harry's projects page</h1>
      <h2 className={responsiveTitle1}>Projects</h2>
      {projectNodes && projectNodes.length > 0 && <PreviewGrid type='projects' nodes={projectNodes} />}
    </Layout>
  )
}

export default function ProjectsPageQuery () {
  return (
    <StaticQuery
      query={graphql`
        query ProjectsPageQuery {
          projects: allSanityProject(
            sort: { fields: [_updatedAt], order: DESC }
            filter: { publishedAt: {ne: null}, slug: { current: { ne: null } } }
          ) {
            edges {
              node {
                id
                mainImage {
                  asset {
                    _id
                  }
                  alt
                }
                title
                _rawExcerpt
                slug {
                  current
                }
              }
            }
          }
        }
      `}
      render={(data) => <ProjectsPage data={data} />}
    />
  )
}
